<template>
  <div id="zalacz">
    <span data-toggle="collapse" data-target="#fileuploadform" class="fw-bold add_images fs-4 d-inline-block"
            onclick="$('#zalacz').toggle(); $('#fileuploadform').toggle()">
          {{ $t('wz_case_form.service_order_data.add_button') }}
      <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.908203 6.94444C0.908203 3.10913 4.01734 0 7.85265 0H18.9638C22.7991 0 25.9082 3.10913 25.9082 6.94444V18.0556C25.9082 21.8909 22.7991 25 18.9638 25H7.85265C4.01734 25 0.908203 21.8909 0.908203 18.0556V6.94444ZM11.2019 6.61763C11.2019 5.39935 12.1895 4.41175 13.4078 4.41175C14.6261 4.41175 15.6137 5.39935 15.6137 6.61763V10.2941H19.2902C20.5084 10.2941 21.496 11.2817 21.496 12.5C21.496 13.7183 20.5084 14.7059 19.2902 14.7059H15.6137V18.3823C15.6137 19.6006 14.6261 20.5882 13.4078 20.5882C12.1895 20.5882 11.2019 19.6006 11.2019 18.3823V14.7059H7.52545C6.30718 14.7059 5.31957 13.7183 5.31957 12.5C5.31957 11.2817 6.30718 10.2941 7.52545 10.2941H11.2019V6.61763Z" fill="#23AE84"/>
      </svg>
    </span>
  </div>
  <div id="fileuploadform" class="collapse">
    <div class="main-pattern "  onclick="$('.instruction').toggle(); $('.down').toggle(); $('.up').toggle();">
      <span class="additional-info">
        <svg fill="#23AE84" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 416.979 416.979"
             xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
      </span>
      <span>Wymagania dotyczące wybranych plików</span>
      <span class="down">
        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z" fill="#23AE84"></path> </g></svg>
      </span>
      <span class="up">
        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z" fill="#23AE84"></path> </g></svg>
      </span>
    </div>
    <div class="instruction h-100">
      <div class="card w-100 h-100 mb-0 pb-0 pl-15">
        <div class="card-body">
          <table class="ml-5 mb-3 d-grid align-items-start">
            <tr>
              <td class="w30px align-items-start pt-2">
                <svg fill="#23AE84" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 400 400" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77 C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769 C373.768,295.816,295.812,373.77,199.996,373.77z"></path> <path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003 c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842 c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546 C285.828,129.402,277.523,129.402,272.406,134.526z"></path> </g> </g> </g></svg>
              </td>
              <td class="w-auto fs-16 pl-10 align-items-start">
                {{ $t('wz_case_form.upload_file.instruction_list_1_1') }}
                <b>{{ $t('wz_case_form.upload_file.instruction_list_1_2') }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="h10px"></td>
            </tr>
            <tr>
              <td class="w30px align-items-start pt-2">
                <svg fill="#23AE84" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 400 400" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77 C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769 C373.768,295.816,295.812,373.77,199.996,373.77z"></path> <path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003 c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842 c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546 C285.828,129.402,277.523,129.402,272.406,134.526z"></path> </g> </g> </g></svg>
              </td>
              <td class="w-auto fs-16 pl-10 align-items-start">
                {{ $t('wz_case_form.upload_file.instruction_list_2_1') }}
                <b>{{ $t('wz_case_form.upload_file.instruction_list_2_2') }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="h10px"></td>
            </tr>
            <tr>
              <td class="w30px align-items-start">
                <svg fill="#23AE84" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 400 400" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77 C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769 C373.768,295.816,295.812,373.77,199.996,373.77z"></path> <path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003 c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842 c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546 C285.828,129.402,277.523,129.402,272.406,134.526z"></path> </g> </g> </g></svg>
              </td>
              <td class="w-auto fs-16 pl-10 align-items-start">
                {{ $t('wz_case_form.upload_file.instruction_list_3_1') }}
                <b>{{ $t('wz_case_form.upload_file.instruction_list_3_2') }}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="main">
      <div
          class="dropzone-container"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
      >
        <input
            type="file"
            multiple
            name="file"
            id="fileInput"
            class="hidden-input"
            @change="onChange"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png"
            size="999"
        />

        <label for="fileInput" class="file-label">
          <div v-if="isDragging">Release to drop files here.</div>
          <div v-else class="mt-2">
            <div class="copy-file">
              <svg fill="#23AE84" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 422.765 422.765" xml:space="preserve" width="50px" height="50px" transform="matrix(-1, 0, 0, 1, 0, 0)" stroke="#23AE84" stroke-width="0.00422765">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="4.22765"> <g> <path d="M276.503,48.541V0H86.493L7.026,79.467v294.757h139.235v48.541h269.478V48.541H276.503z M82.392,23.899v51.467H30.925 L82.392,23.899z M21.026,89.366h75.366V14h166.111v346.224H21.026V89.366z M401.739,408.765H160.261v-34.541h209.518v-14h-93.275 v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14 h-93.275v-42.205h93.275v-14h-93.275V62.541h125.235V408.765z"/> <rect x="52.986" y="104.746" width="177.558" height="14"/> <rect x="52.986" y="160.951" width="177.558" height="14"/> <rect x="52.986" y="190.331" width="177.558" height="14"/> <rect x="52.986" y="219.711" width="177.558" height="14"/> <rect x="52.986" y="249.091" width="177.558" height="14"/> <rect x="52.986" y="278.471" width="177.558" height="14"/> <rect x="52.986" y="307.851" width="177.558" height="14"/> </g> </g>
                <g id="SVGRepo_iconCarrier"> <g> <path d="M276.503,48.541V0H86.493L7.026,79.467v294.757h139.235v48.541h269.478V48.541H276.503z M82.392,23.899v51.467H30.925 L82.392,23.899z M21.026,89.366h75.366V14h166.111v346.224H21.026V89.366z M401.739,408.765H160.261v-34.541h209.518v-14h-93.275 v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14h-93.275v-15.38h93.275v-14 h-93.275v-42.205h93.275v-14h-93.275V62.541h125.235V408.765z"/> <rect x="52.986" y="104.746" width="177.558" height="14"/> <rect x="52.986" y="160.951" width="177.558" height="14"/> <rect x="52.986" y="190.331" width="177.558" height="14"/> <rect x="52.986" y="219.711" width="177.558" height="14"/> <rect x="52.986" y="249.091" width="177.558" height="14"/> <rect x="52.986" y="278.471" width="177.558" height="14"/> <rect x="52.986" y="307.851" width="177.558" height="14"/> </g> </g>
              </svg>
            </div>
            <div>
              <button class="choose-button border-0">
                <span class="button-icon">
                <svg width="20px" height="20px" viewBox="0 -3.47 52.68 52.68" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff" stroke-width="0.7901999999999999"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.21072000000000002"></g><g id="SVGRepo_iconCarrier"> <g id="Group_50" data-name="Group 50" transform="translate(-449.318 -569.91)"> <g id="Group_49" data-name="Group 49"> <path id="Path_31" data-name="Path 31" d="M500.5,615.653h-49.68a1.5,1.5,0,0,1-1.5-1.5v-30.4a1.5,1.5,0,0,1,1.5-1.5h10.466a1.5,1.5,0,1,1,0,3h-8.966v27.4H499v-27.4h-7.929a1.5,1.5,0,0,1,0-3H500.5a1.5,1.5,0,0,1,1.5,1.5v30.4A1.5,1.5,0,0,1,500.5,615.653Z" fill="#ffffff"></path> </g> <path id="Path_32" data-name="Path 32" d="M486.464,593.6a1.5,1.5,0,0,0-2.121,0l-7.185,7.185V571.41a1.5,1.5,0,0,0-3,0v29.379l-7.185-7.186a1.5,1.5,0,1,0-2.121,2.121l9.745,9.745a1.488,1.488,0,0,0,.491.327l.023.007a1.452,1.452,0,0,0,1.094,0l.023-.007a1.492,1.492,0,0,0,.492-.327l9.744-9.745A1.5,1.5,0,0,0,486.464,593.6Z" fill="#ffffff"></path> </g> </g></svg>
              </span>
                Wybierz pliki
              </button>
            </div>
            <p class="under-button-label">lub upuść pliki tutaj</p>
          </div>
        </label>
        <!-- Note: Only add the code block below -->
        <div class="preview-container mt-4 mb-3" v-if="files.length">
          <div v-for="file in files" :key="file.name" class="preview-card">
            <div>
              <img class="preview-img"  :src=generateURL(file)  />
              <p>
                {{ file.name }}
                {{ Math.round(file.size / 1000) + "kb" }}
              </p>
            </div>
            <div>
              <button
                  class="ml-2 btn-close"
                  type="button"
                  @click="remove(files.indexOf(file))"
                  title="Remove file"
              >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  emits: ["getDroppedFiles"],
  methods: {
    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
          incomingFiles.some(
              (file) => file.name === r.name && file.size === r.size
          )
      );
      if (fileExist) {
        self.showMessage = true;
        alert("New upload contains files that already exist");
      } else {
        self.files.push(...incomingFiles);
      }
      this.$emit('getDroppedFiles', self.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
  },
};
</script>
<style>
.main-pattern {
  background-color: rgba(35,174,132, 0.2);
  color: #23AE84;
  padding: 7px;
  border-radius: 20px;
  border: none;
  z-index: 999;
  position: relative;
}
.main-pattern span{
 font-weight: 600;
}
.main-pattern .additional-info {
  margin-right: 10px;
  margin-left: 10px;
}
.down {
  float: right;
  top: -8px;
  position: relative;
}
.up {
  display: none;
  float: right;
  top: -8px;
  position: relative;
}
.main {
  flex-grow: 1;
  align-items: center;
  align-content: center;
  min-height: 200px;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  border: #23AE84 3px dotted;
  width: 100%;
  margin-top: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-10 {
  padding-left: 10px;
}
.dropzone-container {
  height: auto;
}
.fs-16 {
  font-size: 16px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: center;
}

.preview-card {
  display: flex;
  max-width: 30%;
  border: 1px solid #23AE84;
  padding: 5px;
  margin: 0 2px 5px 2px;
  color: #23AE84;
  border-radius: 10px;
  font-family: Mulish;
  justify-content: center;
}
.preview-card .btn-close {
  color: #23AE84;
}
.add_images {
  color: #23AE84;
  margin-bottom: 20px;
}
.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #23AE84;
  background-color: #23AE84;
}
.copy-file {
  width: 100%;
  height: auto;
}
.choose-button {
  width: 150px;
  height: 40px;
  background-color: #23AE84;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  font-family: Mulish;
  margin-top: 15px;
  align-content: center;
  font-weight: 700;
}
.button-icon {
  display: inline-block;
  position: relative;
  top: -2px;
}
.under-button-label {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  color: #23AE84;
  margin-top: 10px;
  text-align: center;
}
.h10px {
  height: 10px;
}
.instruction {
  display: none;
  border: none;
  top: -38px;
  position: relative;
}
.instruction .card {
  border: none;
  box-shadow: 0 10px 10px #ccc;
  padding-top: 40px;
  border-radius: 20px;
}
</style>